// 仅支持 chrome 滚动条样式

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
    width: 5px;
    background-color: #fff;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ccc;
}

.base-style {
    background-color: #fff;
    margin-bottom: 1rem;
    padding: 1.5rem;
    border-radius: 5px;
    box-shadow: 0 2px 13px 0 rgba(228, 228, 228, 0.6);
}

.ant-table-body {
    &::-webkit-scrollbar {
        height: 8px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #ccc;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: 0;
        border-radius: 0;
        background: #fff;
    }
}

// @font-face {
//     font-family: 'webfont';
//     font-display: swap;
//     src: url('../assets/font/webfont.eot'); /* IE9 */
//     src: url('../assets/font/webfont.eot') format('embedded-opentype'), /* IE6-IE8 */
//     url('../assets/font/webfont.woff2') format('woff2'),
//     url('../assets/font/webfont.woff') format('woff'), /* chrome、firefox */
//     url('../assets/font/webfont.ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
//     url('../assets/font/webfont.svg') format('svg'); /* iOS 4.1- */
// }

// html,
// body {
//     font-family: 'webfont' !important;
// }

.dark-row {
    background-color: rgba(3, 169, 245, 0.05);
}

.ant-table-tbody > tr > td {
    padding: 12px !important;
    font-size: 13px;
    font-weight: 400;
    // color: #606266;
}
.ant-table-column-title {
    font-size: 13px;
    font-family: 'Microsoft YaHei';
    font-weight: bold;
    color: #909399;
    // line-height: 50px;
}
.ant-btn {
  font-size: 13px !important;
}
.ant-tabs-bar {
  -webkit-box-shadow:0px 1px 5px #e6ebf5;
  -moz-box-shadow:0px 1px 5px #e6ebf5;
  box-shadow:0px 1px 5px #e6ebf5;
}
.defaultHeight {
  height: 2rem;
}
.defaultWidth {
  width: 2rem;
}
